import classNames from "classnames";
import Link from "next/link";
import React, { Children, ReactElement, ReactNode } from "react";

type Props = {
  className?: string;
  children: ReactNode | ReactNode[];
  href: string | URL;
  as?: string | URL;
  standAlone?: boolean;
};

const TextLink = ({
  className = "",
  children,
  standAlone = false,
  ...props
}: Props) => {
  let child;
  if (Array.isArray(children)) {
    child = Children.only(children[0]) as ReactElement;
  } else {
    child = Children.only(children) as ReactElement;
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Link {...props} legacyBehavior>
      {React.cloneElement(child, {
        className: classNames(
          "underline text-primary-500 hover:no-underline hover:text-primary-400 focus:outline-none focus:ring-1 focus:ring-primary-500",
          { "font-medium": standAlone },
          { "font-normal": !standAlone },
          className
        ),
      })}
    </Link>
  );
};

export default TextLink;
